.landscape-section-contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 75em) {
    .landscape-section-contact {
      flex-direction: column;
      height: auto; } }
  .landscape-section-contact .landscape-section-contact-why-us {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px; }
    .landscape-section-contact .landscape-section-contact-why-us i {
      font-size: 28px;
      font-size: 60px;
      margin-bottom: 10px; }
      .landscape-section-contact .landscape-section-contact-why-us i.fa-map-marker-alt {
        color: orange; }
    .landscape-section-contact .landscape-section-contact-why-us .landscape-section-contact-why-us-text {
      text-align: justify;
      margin-top: -15px; }
      .landscape-section-contact .landscape-section-contact-why-us .landscape-section-contact-why-us-text p {
        font-size: 14px; }
    .landscape-section-contact .landscape-section-contact-why-us .landscape-section-contact-why-us-quality svg {
      color: cornflowerblue; }
    .landscape-section-contact .landscape-section-contact-why-us .landscape-section-contact-why-us-health svg {
      color: forestgreen; }
    @media screen and (max-width: 767px) {
      .landscape-section-contact .landscape-section-contact-why-us h5,
      .landscape-section-contact .landscape-section-contact-why-us .landscape-section-contact-why-us-text p {
        padding-left: 20px;
        padding-right: 20px; } }
  .landscape-section-contact .landscape-section-contact-logos {
    width: 100%;
    margin-bottom: 40px; }
    .landscape-section-contact .landscape-section-contact-logos .landscape-section-contact-logos-item {
      max-width: 100%;
      min-width: 220px; }
    .landscape-section-contact .landscape-section-contact-logos a[role="button"] {
      cursor: default;
      color: #0081eb; }
      .landscape-section-contact .landscape-section-contact-logos a[role="button"] > span {
        flex-wrap: wrap; }
    .landscape-section-contact .landscape-section-contact-logos span.icon-text {
      flex-basis: 100%;
      margin-top: 10px;
      transition: 0.25s ease-in opacity;
      transition-delay: 0s;
      opacity: 0; }
      .landscape-section-contact .landscape-section-contact-logos span.icon-text.small {
        text-transform: none;
        font-size: 16px; }
    .landscape-section-contact .landscape-section-contact-logos i {
      transform: translateY(20px);
      transition: 0.25s ease-in transform;
      transition-delay: 0.25s;
      font-size: 40px !important; }
      .landscape-section-contact .landscape-section-contact-logos i.fab {
        transform: translateY(20px);
        position: relative;
        width: 40px;
        height: 40px;
        top: unset;
        left: unset;
        margin: 0;
        line-height: 40px !important; }
      .landscape-section-contact .landscape-section-contact-logos i.fa-whatsapp,
      .landscape-section-contact .landscape-section-contact-logos i.fa-whatsapp + .icon-text {
        color: limegreen; }
      .landscape-section-contact .landscape-section-contact-logos i.fa-telegram,
      .landscape-section-contact .landscape-section-contact-logos i.fa-telegram + .icon-text {
        color: deepskyblue; }
      .landscape-section-contact .landscape-section-contact-logos i.fa-instagram,
      .landscape-section-contact .landscape-section-contact-logos i.fa-instagram + .icon-text {
        color: #833ab4; }
    .landscape-section-contact .landscape-section-contact-logos a[role="button"]:hover {
      cursor: pointer; }
      .landscape-section-contact .landscape-section-contact-logos a[role="button"]:hover i {
        transform: translateY(0);
        transition-delay: 0s; }
      .landscape-section-contact .landscape-section-contact-logos a[role="button"]:hover span.icon-text {
        opacity: 1;
        transition: 0.25s ease-in 0.25s opacity; }
    @media screen and (max-width: 1200px) {
      .landscape-section-contact .landscape-section-contact-logos .landscape-section-contact-logos-item {
        min-width: 150px; }
      .landscape-section-contact .landscape-section-contact-logos i {
        font-size: 30px !important; }
      .landscape-section-contact .landscape-section-contact-logos span.icon-text {
        font-size: 14px; }
        .landscape-section-contact .landscape-section-contact-logos span.icon-text.small {
          font-size: 12px; } }
    @media screen and (max-width: 1000px) {
      .landscape-section-contact .landscape-section-contact-logos .landscape-section-contact-logos-item {
        min-width: 100px;
        border-right: 0; } }
