.landscape-section-form-contact {
  padding-top: 0 !important;
  max-width: 1300px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .landscape-section-form-contact {
      padding-left: 30px !important;
      padding-right: 30px !important; } }
  .landscape-section-form-contact .landscape-section-form-contact-buttons {
    display: flex;
    justify-content: center; }
    .landscape-section-form-contact .landscape-section-form-contact-buttons button {
      min-width: 200px; }
  .landscape-section-form-contact .landscape-section-form-contact-snackbar {
    margin-top: 20px; }
