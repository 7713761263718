.landscape-section-features .landscape-section-features-container {
  max-width: 1300px !important; }

.landscape-section-features h2 {
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 30px; }

.landscape-section-features .grid-item:nth-child(even) h6 {
  color: #00acc1; }

.landscape-section-features .grid-item:nth-child(odd) h6 {
  color: #4caf50; }

.landscape-section-features .card {
  margin-top: 30px;
  margin-bottom: 0; }
  .landscape-section-features .card a > img {
    width: 100%;
    height: 200px;
    object-fit: cover; }
  .landscape-section-features .card h4 {
    text-align: justify; }
  .landscape-section-features .card p {
    text-align: justify;
    color: #666; }

.landscape-section-features .landscape-section-features-items {
  list-style: none;
  padding: 0;
  margin: 0; }
  .landscape-section-features .landscape-section-features-items li {
    color: #666; }
    .landscape-section-features .landscape-section-features-items li svg {
      margin-right: 10px;
      color: forestgreen; }
  .landscape-section-features .landscape-section-features-items + p {
    margin-top: 10px; }

#piscinesComWidgetContainer {
  position: absolute;
  top: 100vh;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  padding: 30px 0; }
  #piscinesComWidgetContainer > div {
    width: 100%; }

#piscinesComWidget {
  height: 100px; }
