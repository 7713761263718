.landscape-parallax #landscape-logo {
  position: absolute;
  width: calc(100% - 100px);
  top: 50px;
  left: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  @media screen and (max-width: 1000px) {
    .landscape-parallax #landscape-logo {
      justify-content: center; } }
  .landscape-parallax #landscape-logo img {
    max-width: 300px; }

.landscape-parallax .landscape-parallax-content {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap; }
  .landscape-parallax .landscape-parallax-content h1 {
    background: none;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 80px;
    font-family: serif;
    margin: 0; }
    @media screen and (max-width: 1000px) {
      .landscape-parallax .landscape-parallax-content h1 {
        font-size: 50px; } }
  .landscape-parallax .landscape-parallax-content h4 {
    font-size: 18px; }
    @media screen and (max-width: 1000px) {
      .landscape-parallax .landscape-parallax-content h4 {
        font-size: 16px; } }

.landscape-parallax .landscape-parallax-go-bottom {
  width: 100%;
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .landscape-parallax .landscape-parallax-go-bottom a {
    color: white;
    font-size: 40px;
    width: 75px;
    height: 75px;
    border: thin solid white;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .landscape-parallax .landscape-parallax-go-bottom a:hover {
      background-color: rgba(255, 255, 255, 0.2); }

.go-to-contact-link {
  cursor: pointer; }
  .go-to-contact-link:hover {
    color: #0081eb;
    text-decoration: underline; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }
