.landscape-section-pricing .landscape-section-pricing-cards {
  margin: 0;
  display: flex;
  justify-content: center; }
  .landscape-section-pricing .landscape-section-pricing-cards > div {
    margin: 0 !important;
    max-width: 400px;
    min-width: 300px;
    flex-basis: auto;
    width: auto;
    flex-grow: 1; }
    @media screen and (max-width: 767px) {
      .landscape-section-pricing .landscape-section-pricing-cards > div {
        max-width: 100%; } }
  .landscape-section-pricing .landscape-section-pricing-cards .smallest {
    font-size: 14px;
    top: -25px;
    left: 5px; }
