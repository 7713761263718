.landscape-section-footer {
  height: 100px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  .landscape-section-footer > div {
    font-size: 20px;
    color: #fff;
    background: #0081eb;
    padding: 15px 20px; }
